import axios from "axios";
import config from "../config";

//config.chatApiServer

export const getRoomExists = async (roomId) => {
  const response = await axios.get(
    `${config.chatApiServer}/api/v1/room-exists/${roomId}`
  );
  return response.data;
};

export const getTURNCredentials = async () => {
  const response = await axios.get(
    `${config.chatApiServer}/api/v1/get-turn-credentials`
  );
  return response.data;
};

//Login related  API calls
export const getCofferLogin = async (email, password, plan, loginType) => {
  const postData =
    loginType === "ind"
      ? { email, password, logintype: "email", action: "login" }
      : {
          email,
          password,
          plan,
        };
  const { data } = await axios.post(
    `${config.cofferApiServer}/${
      loginType === "ind" ? "consumer" : "professional"
    }/login`,
    postData,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

export const getUserEmail = async (url, authToken, loginType) => {
  const config = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };
  const { data } = await axios.get(
    `${url}/${
      loginType === "ind" ? "consumer/profile" : "professional/v3/chatemail"
    }`,
    config
  );
  return data;
};

export const uploadMediaFile = async (content) => {
  // const postData = { content };
  await axios
    .post(`${config.transServer}/api/v1/trans/upload`, content, {
      headers: {
        "Content-Type": "audio/wav",
      },
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
    })
    .then((response) => {
      console.log("Upload successful:", response.data);
    })
    .catch((error) => {
      console.error("Error uploading file:", error);
    });
};
