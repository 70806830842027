import React from "react";
import Messages from "./Messages";
import { setShowChat } from "../../store/actions";
import { connect } from "react-redux";
import { Tooltip } from "react-tooltip";
import NewMessage from "./NewMessage";

const PopupMessages = (props) => {
  const { setShowChatAction } = props;
  const closeDiv = () => {
    setShowChatAction(false);
  };
  return (
    <div className="participants_popup_container open">
      <Tooltip id="my-tooltip" />
      <div className="fixed-top">
        <p className="popup-title">In-call Messages</p>
        <span
          className="close-btn"
          onClick={closeDiv}
          data-tooltip-id="my-tooltip"
          data-tooltip-content="Close"
        >
          ×
        </span>
      </div>
      <div className="chat-popup-container">
        <Messages />
        <NewMessage />
      </div>
    </div>
  );
};

const mapActionsToProps = (dispatch) => {
  return {
    setShowChatAction: (showChat) => dispatch(setShowChat(showChat)),
  };
};

export default connect(null, mapActionsToProps)(PopupMessages);
