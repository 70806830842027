import React, { useEffect } from "react";
import ParticipantsSection from "./ParticipantsSection/ParticipantsSection";
import "./roompage.css";
import VideoSection from "./VideoSection/VideoSection";
import ChatSection from "./ChatSection/ChatSection";
import RoomLabel from "./RoomLabel";
import { connect } from "react-redux";
import * as webRTCHandler from "./../utils/webRTCHandler";
import Overlay from "./Overlay";
import { setFromJid, setToJid } from "../store/actions";

const Roompage = ({
  roomId,
  identity,
  isRoomHost,
  showOverlay,
  connectOnlyWithAudio,
  fromJid,
  toJid,
}) => {
  useEffect(() => {
    if (!isRoomHost && !roomId) {
      const siteUrl = window.location.origin;
      window.location.href = siteUrl;
    } else {
      webRTCHandler.getLocalPreviewAndInitRoomConnection(
        isRoomHost,
        identity,
        roomId,
        connectOnlyWithAudio,
        fromJid,
        toJid
      );
    }
  }, []);

  return (
    <div className="room_container">
      {/* <ParticipantsSection /> */}
      <VideoSection />
      {/* <ChatSection /> */}
      {/* <RoomLabel roomId={roomId} /> */}
      {showOverlay && <Overlay />}
    </div>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    setFromJidAction: (fromJid) => dispatch(setFromJid(fromJid)),
    setToJidAction: (toJid) => dispatch(setToJid(toJid)),
  };
};

export default connect(mapStoreStateToProps, mapActionsToProps)(Roompage);
