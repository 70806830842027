import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import JoinRoompage from "./joinroompage/JoinRoompage";
import Roompage from "./roompage/Roompage";
import Dashboard from "./dashboard/Dashboard";
import Login from "./login/Login";
import Users from "./users/Users";
import { connectWithSocketIOServer } from "./utils/wss";
import "./App.css";

function App() {
  useEffect(() => {
    "Connect with Socket IO Server called...";
    connectWithSocketIOServer();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {/* <Route exact path="/" Component={Dashboard} /> */}
        <Route path="/" Component={Users} />
        <Route path="join-room" Component={JoinRoompage} />
        <Route path="room" Component={Roompage} />
        <Route path="login" Component={Login} />
        {/* <Route path="users" Component={Users} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
