import React from "react";
import { connect } from "react-redux";
import { getLocalTime } from "../../utils/common";

const Message = ({
  author,
  content,
  sameAuthor,
  messageCreatedByMe,
  sentDateTime,
}) => {
  const alignClass = messageCreatedByMe
    ? "message_align_right"
    : "message_align_left";
  const authorText = messageCreatedByMe ? `You` : `${author}`;
  const additionalStyles = messageCreatedByMe
    ? "message_right_styles"
    : "message_left_styles";
  return (
    <div className={`message_container ${alignClass}`}>
      <div className="message_title_date_container">
        {!sameAuthor && <p className="message_title">{authorText}</p>}
        {!sameAuthor && (
          <p className="message_date_title">{getLocalTime(sentDateTime)}</p>
        )}
      </div>

      <p className={`message_content ${additionalStyles}`}>{content}</p>
    </div>
  );
};

const Messages = (props) => {
  const { messages } = props;
  return (
    <div className="messages_container">
      {messages.map((message, index) => {
        const sameAuthor =
          index > 0 && message.identity === messages[index - 1].identity;

        return (
          <Message
            key={`${message.content}${index}`}
            author={message.identity}
            content={message.content}
            sameAuthor={sameAuthor}
            messageCreatedByMe={message.messageCreatedByMe}
            sentDateTime={new Date(message.sentDateTime)}
          />
        );
      })}
    </div>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStoreStateToProps)(Messages);
