import React from "react";

const ParticipantsLabel = ({ title }) => {
  return (
    <div className="participants_label_container">
      <p className="participants_label_paragraph">{title}</p>
    </div>
  );
};

export default ParticipantsLabel;
