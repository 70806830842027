import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import config from "../config";
import store from "../store/store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import axios from "axios";
import { Strophe, $msg, $pres, $iq } from "strophe.js";
import { connect } from "react-redux";
import "./users.css";
import { getUserEmail } from "../utils/api";

import {
  setFromJid,
  setToJid,
  setIsRoomHost,
  setIdentity,
  setInRequest,
  setCofferToken,
  setCallFrom,
  setConnection,
  setClientUsers,
  setTeam,
  setLoginType,
  setCofferEmail,
} from "../store/actions";
import * as webRTCHandler from "../utils/webRTCHandler";

let expId = "0";
let expClientId = "";
const Users = (props) => {
  const {
    setIdentityAction,
    setFromJidAction,
    setToJidAction,
    setIsRoomHostAction,
    setInRequestAction,
    setCofferTokenAction,
    setLoginTypeAction,
    setTeamAction,
    setConnectionAction,
    setClientUsersAction,
    setCofferEmailAction,
    inRequest,
    callFrom,
  } = props;
  const authData = store.getState().cofferAuthData;
  const cofferToken = store.getState().cofferToken;
  let loginType = store.getState().loginType;

  const apptInfo = [];
  const decodedToken = {
    jid: "",
  };

  const isObjectEmpty = (objectName) => {
    return Object.keys(objectName).length === 0;
  };

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  let authToken =
    searchParams.get("token") === null
      ? cofferToken
      : searchParams.get("token");

  if (loginType === "") {
    loginType =
      searchParams.get("logintype") === null
        ? "pro"
        : decodeURIComponent(searchParams.get("logintype"));
    setLoginTypeAction(loginType);
  }

  const myJidValue =
    searchParams.get("jid") === null
      ? ""
      : decodeURIComponent(searchParams.get("jid"));

  const fullName =
    searchParams.get("name") === null
      ? ""
      : decodeURIComponent(searchParams.get("name"));

  // console.log(authData);
  let myJid =
    authData.uid === undefined || authData.uid === "undefined"
      ? myJidValue
      : loginType === "ind"
      ? authData.uid
      : `${authData.uid}_${authData.user_id}`;

  // console.log(cofferToken, authToken, myJid);
  if (cofferToken === "" && authToken === "" && myJid === "") {
    // navigate("/login");
    const siteUrl = window.location.origin;
    window.location.href = `${siteUrl}/login`;
  } else {
    if (myJid.indexOf(`@${config.xmppServer}`) === -1)
      myJid = myJid + `@${config.xmppServer}`;

    if (store.getState().cofferToken === "") {
      // console.log("assigning store values");
      // console.log(
      //   cofferToken === "" ? authToken : cofferToken,
      //   myJid,
      //   fullName === "" ? authData.name : fullName
      // );
      setCofferTokenAction(cofferToken === "" ? authToken : cofferToken);
      setFromJidAction(myJid);
      //Tricky code below because the way consumer and professional endpoint return different  keys
      setIdentityAction(
        fullName === ""
          ? loginType === "ind"
            ? authData.first_name + " " + authData.last_name
            : authData.name
          : fullName
      );
    }
  }
  // else {
  //   webRTCHandler.sendLoggedInInfo(myJid, fullName);
  // }
  //   const loginState = useSelector((state) => state.loginState);
  const loginState = true;

  const [clients, setClients] = useState([]);
  const [clientUsers, setClientUsers] = useState([]);
  const [newClients, setNewClients] = useState([]);
  const [team, setTeam] = useState([]);
  const [newTeam, setNewTeam] = useState([]);
  const [prevClientId, setPrevClientId] = useState("");
  const [newClientUsers, setNewClientUsers] = useState([]);
  const [receivedPresenceUpdate, setReceivedPresenceUpdate] = useState(false);
  const [myPresence, setMyPresence] = useState({
    show: "chat", // Default presence status, can be 'chat', 'away', etc.
    status: "Available", // Default status message
  });
  const [prevId, setPrevId] = useState("");
  const [connection, setConnection] = useState(null);
  const [socketConnected, setSocketConnected] = useState(false);

  const makeCall = (client) => {
    // console.log(client);
    setIdentityAction(
      fullName === ""
        ? loginType === "ind"
          ? authData.first_name + " " + authData.last_name
          : authData.name
        : fullName
    );
    setIsRoomHostAction(true);
    setFromJidAction(myJid);
    setToJidAction(`${client.toJid}@${config.xmppServer}`);
    setConnectionAction(newClients);
    setClientUsersAction(newClientUsers);
    setTeamAction(newTeam);
    navigate("/room");
  };

  const joinCall = () => {
    //setRoomIdAction(roomIdValue);
    setConnectionAction(newClients);
    setTeamAction(newTeam);
    navigate(`/room`);
  };

  const toggleClientExpand = async (itemId) => {
    //following code is less process intensive, but for some issue so need to check
    if (prevClientId !== itemId) {
      const cl = newClients.find((client) => client.id === itemId);
      // console.log(cl);
      if (cl) setNewClientUsers(cl.users);
      else setNewClientUsers([]);
    }
    setNewClients((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, isExpanded: !item.isExpanded } : item
      )
    );
    //collapse the previous expanded object
    if (prevClientId !== "" && prevClientId !== itemId) {
      expClientId = itemId;
      setNewClients((prevItems) =>
        prevItems.map((item) =>
          item.id === prevClientId ? { ...item, isExpanded: false } : item
        )
      );
    } else {
      if (prevClientId !== "") {
        if (isUsersAnyExpanded()) expClientId = "";
        else expClientId = itemId;
      } else {
        expClientId = itemId;
      }
    }
    setPrevClientId(itemId);
  };

  const isAnyExpanded = () => {
    const dataSet = newTeam.find((team) => team.isExpanded);
    if (dataSet) {
      return true;
    } else {
      return false;
    }
  };

  const isUsersAnyExpanded = () => {
    const dataSet = newClients.find((user) => user.isExpanded);
    if (dataSet) {
      return true;
    } else {
      return false;
    }
  };

  const toggleExpand = (itemId) => {
    //Expand the current object
    setNewTeam((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, isExpanded: !item.isExpanded } : item
      )
    );
    //collapse the previous expanded object
    if (prevId !== "" && prevId !== itemId) {
      expId = itemId;
      // if (isAnyExpanded()) expId = "";
      // else expId = itemId;
      setNewTeam((prevItems) =>
        prevItems.map((item) =>
          item.id === prevId ? { ...item, isExpanded: false } : item
        )
      );
    } else {
      if (prevId !== "") {
        if (isAnyExpanded()) expId = "";
        else expId = itemId;
        //expId = itemId;
        // console.log(`expId = ${expId}`);
      } else {
        expId = itemId;
      }
    }
    setPrevId(itemId);
  };

  const getCorpClients = async (relClients) => {
    return new Promise(async (resolve) => {
      // const bearer_token = sessionStorage.getItem("latoken");
      //const bearer_token =
      // "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiI5RjNCOTNDMTE3NkNFRTlBIiwiYWRtaW4iOmZhbHNlLCJwbGFuIjoibGF1ZGl0b3IiLCJyb2xlIjoiU1UiLCJuYW1lIjoiU2F0aGlzaCIsInVzZXJfaWQiOiI2M2ZkOGE3YTM4NzQ1OWQ1YmZjOWQ1NDQiLCJleHAiOjE3MDUzMzY5NTJ9.iON4p5_bgW16Z1ozT_-NYWDweo9IJOU7G1yV7j-3HUc";
      const prodUrl = `${config.cofferApiServer}/professional/v3/corporate`;
      // const restUrl = `${config.cofferApiServer}/professional/relationship`;
      let clientData = [];
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        };
        const response = await axios.get(prodUrl, config);
        clientData = await response.data.relationships;
        resolve(relClients.concat(clientData));
        // clientData = await addOnlineStatus(clientData, restUrl);

        // console.log(clientUsers);
        // console.log(clients);
        // setClientUsers(clientUsers.concat(clientData));

        // setClients(clients.concat(clientData));
      } catch (error) {
        console.log(error);
      }
    });
  };

  const getClients = async () => {
    return new Promise(async (resolve) => {
      // const bearer_token = sessionStorage.getItem("latoken");
      //const bearer_token =
      // "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiI5RjNCOTNDMTE3NkNFRTlBIiwiYWRtaW4iOmZhbHNlLCJwbGFuIjoibGF1ZGl0b3IiLCJyb2xlIjoiU1UiLCJuYW1lIjoiU2F0aGlzaCIsInVzZXJfaWQiOiI2M2ZkOGE3YTM4NzQ1OWQ1YmZjOWQ1NDQiLCJleHAiOjE3MDUzMzY5NTJ9.iON4p5_bgW16Z1ozT_-NYWDweo9IJOU7G1yV7j-3HUc";
      let prodUrl = "";
      let restUrl = "";
      console.log(` login type == ${loginType}`);
      if (loginType === "ind") {
        prodUrl = `${config.cofferApiServer}/consumer/relationships`;
        restUrl = `${config.cofferApiServer}/consumer/relationships`;
      } else {
        prodUrl = `${config.cofferApiServer}/professional/v2/relationship`;
        restUrl = `${config.cofferApiServer}/professional/relationship`;
      }

      let clientData = [];
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        };
        const response = await axios.get(prodUrl, config);
        clientData = await response.data.data.relationships;
        if (loginType === "pro") {
          clientData = await getCorpClients(clientData);
          await getTeam();
        }
        clientData = await addOnlineStatus(clientData, restUrl);

        console.log(clientUsers);
        console.log(clients);
        setClientUsers(clientData);
        // console.log(` <> Clients <>`);
        // console.log(clientData);
        // console.log(clientUsers);

        setClients(clientData);
      } catch (error) {
        console.log(error);
      }
    });
  };

  const addOnlineStatus = async (clients, restUrl) => {
    return new Promise(async (resolve) => {
      let clientData = clients;
      const totalRows = clients.length;
      let row = 1;
      clientData.forEach((client) => {
        row++;
        //restUrl = `${config.cofferApiServer}/professional/relationship/${client.id}/users/notify`;
        (async () => {
          let clType = "";
          if (loginType === "ind") {
            clType = client.biztype;
          } else {
            clType = client.clientType;
          }
          if (clType !== "consumer") {
            let users = await getClientUsers(restUrl, client.id, loginType);
            users.forEach((obj) => {
              obj["online"] = false;
            });
            client["users"] = users;
            client["online"] = false;
            client["isExpanded"] = false;
            // console.log(`Assined users `);
          } else {
            client["users"] = [];
            client["online"] = false;
            client["isExpanded"] = false;
          }
        })();
      });
      if (row >= totalRows) {
        setClients(clientData);
        setNewClients(clientData);
        // console.log(`new Clients >>`);
        // console.log(newClients);
      }
      resolve(clientData);
    });
  };

  const getClientUsers = async (restUrl, clientId, loginType) => {
    return new Promise(async (resolve) => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        };
        const apiUrl =
          loginType === "pro"
            ? `${restUrl}/${clientId}/users/notify`
            : `${restUrl}/${clientId}/users/notify`;

        const response = await axios.get(apiUrl, config);
        resolve(response.data.data.users);
      } catch (error) {
        console.error(error);
        resolve([]);
      }
    });
  };

  const getTeam = async () => {
    //professional/chatusers
    const prodUrl = `${config.cofferApiServer}/professional/chatusers`;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await axios.get(prodUrl, config);
      const chatTeam = await response.data.groups;
      setTeam(chatTeam);

      const nTeam = chatTeam.map((group) => ({
        ...group,
        users: group.users.map((user) => ({ ...user, online: false })),
      }));

      // console.log(`<<< Chat Team >>>`);
      // console.log(nTeam);

      setNewTeam(nTeam);
      //setApptInfo(clients)
    } catch (error) {
      console.log(error);
    }
  };

  const changeOnlineStatus = (jid, newStatus) => {
    // console.log(` Received presence from <<${jid}>>`);
    let nClients = newClients;
    let nTeam = newTeam;
    let cUsers = newClientUsers;
    nClients.forEach((client) => {
      //Unsure when to send client offline, in this case. Otherwise it is toggling
      // client.online = client.guid === jid ? true : false;
      client.isExpanded = expClientId === client.id ? true : false;
      client.online = client.guid === jid ? true : client.online;
    });
    // setNewClients((prevStates) =>
    //   prevStates.map((client) =>
    //     client.guid === jid ? { ...client, online: newStatus } : client
    //   )
    // );
    nTeam.forEach((group) => {
      group.users.forEach((user) => {
        group.isExpanded = expId === group.id ? true : false;
        user.online = user.guid === jid ? true : user.online;
      });
    });

    cUsers.forEach((user) => {
      //Unsure when to send client offline, in this case. Otherwise it is toggling
      // client.online = client.guid === jid ? true : false;
      const isOnline = user.online ? user.online : false;
      user.online = user.guid === jid ? true : isOnline;
    });
    // console.log(cUsers);
    setClients(nClients);
    setNewClients(nClients);
    setNewTeam(nTeam);
    setNewClientUsers(cUsers);
  };

  // useEffect(() => {
  //   if (!isConnected) {
  //     console.log("<<< Calling connectWithSocketIOServer >>>>");
  //     connectWithSocketIOServer();
  //     setSocketConnected(true);
  //   } else {
  //     setSocketConnected(true);
  //     console.log("Socket is available  and connected...");
  //   }
  // }, [authToken, myJid]);

  useEffect(() => {
    if (!socketConnected) {
      const delayInterval = setInterval(() => {
        updateSocketInfo();
        setSocketConnected(true);
      }, 3000); // 3000 milliseconds = 3 seconds
      return () => {
        clearInterval(delayInterval);
      };
    }
  }, [socketConnected]);

  const updateSocketInfo = () => {
    console.log("<<< Calling SendLoginInfo >>>>");
    const logFullName =
      loginType === "ind"
        ? authData.first_name + " " + authData.last_name
        : authData.name;
    webRTCHandler.sendLoggedInInfo(
      myJid,
      fullName === "" ? logFullName : fullName
    );
  };

  useEffect(() => {
    if (inRequest) {
      setInRequestAction(false);
      console.log("alert...");

      confirmAlert({
        title: "Video Call",
        message: `Accept Call from ${callFrom} ?`,
        buttons: [
          {
            label: "Yes",
            onClick: () => joinCall(true),
          },
          {
            label: "No",
            onClick: () => null,
          },
        ],
        closeOnClickOutside: false,
      });
    }
  }, [inRequest]);

  useEffect(() => {
    if (authToken !== "") {
      const fetchClient = async () => {
        await getClients();
      };
      fetchClient();
      // console.log(`API Call - not working ${loginType}`);
      // if (loginType !== "ind") {
      //   await getTeam();
      // }
    }
  }, [authToken]);

  useEffect(() => {
    if (authToken !== "" && loginType !== "" && loginType !== undefined) {
      const fetchEmail = async () => {
        const userEmailData = await getUserEmail(
          config.cofferApiServer,
          authToken,
          loginType
        );
        if (loginType === "ind") {
          if (userEmailData.data.email) {
            setCofferEmailAction(userEmailData.data.email);
            console.log(`Setting Email => ${userEmailData.data.email}`);
          }
        } else {
          if (userEmailData.email) {
            setCofferEmailAction(userEmailData.email);
            console.log(`Setting Email => ${userEmailData.email}`);
          }
        }
      };
      fetchEmail();
    }
  }, [authToken, loginType]);

  useEffect(() => {
    // Initialize XMPP connection
    // console.log(loginState);
    if (cofferToken !== "" || (authToken !== "" && myJid !== "")) {
      const xmppConnection = new Strophe.Connection(
        `wss://${config.xmppServer}:5443/ws`,
        { protocol: "wss" }
      );
      // console.log(xmppConnection);
      // const myJid = authData.uid + "_" + authData.user_id;

      console.log(`connecting as ${myJid}`);
      xmppConnection.connect(myJid, authToken, onConnect);
      setConnection(xmppConnection);

      function onConnect(status) {
        // console.log(`current status = ${status}`);
        // console.log(`open = ${Strophe.Status.CONNECTED}`);
        if (status === Strophe.Status.CONNECTED) {
          console.log("connected...");
          setupMessageListener();
          //for loop here to each of the clients
          //#SATHIKUM need to call api /notify for each relationship and then subscribe
          //https://apidev2.digicoffer.com/professional/relationship/65b9e6c9a1db720418e50c8a/users/notify
          clients.forEach((cl) => {
            subscribeToPresence(`${cl.guid}@${config.xmppServer}`);
          });
          //Team Member code here
          team.forEach((tm) => {
            tm.users.forEach((user) => {
              subscribeToPresence(`${user.guid}@${config.xmppServer}`);
            });
          });

          newClientUsers.forEach((cl) => {
            subscribeToPresence(`${cl.guid}@${config.xmppServer}`);
          });
        } else if (status === Strophe.Status.DISCONNECTED) {
          console.log("Disconnected from XMPP server");
        }
      }

      const setupMessageListener = () => {
        const presenceStanza = $pres();
        presenceStanza.c("show").t("chat"); // 'chat' represents 'available' status
        presenceStanza.c("status").t("Available for a chat");

        xmppConnection.deleteHandler(onPresence);
        xmppConnection.addHandler(onPresence, null, "presence");
        //xmppConnection.send($pres()); // Send presence to indicate online status
        xmppConnection.send(presenceStanza);
      };

      const sendPresence = () => {
        const presenceStanza = $pres();
        presenceStanza.c("show").t("chat"); // 'chat' represents 'available' status
        presenceStanza.c("status").t("Available for a chat");
        xmppConnection.deleteHandler(onPresence);
        xmppConnection.addHandler(onPresence, null, "presence");
        xmppConnection.send(presenceStanza);
        // console.log("presence sent --->");
      };

      const onPresence = (presence) => {
        // console.log(`Received prssence => ${presence}`);
        // console.log(presence);
      };

      const subscribeToPresence = (jid) => {
        xmppConnection.deleteHandler(onPresenceUpdate);

        xmppConnection.addHandler(
          onPresenceUpdate,
          null,
          "presence",
          null,
          null,
          jid,
          { matchBare: true }
        );
        xmppConnection.send($pres({ to: jid }));
      };

      const onPresenceUpdate = (presenceStanza) => {
        // Handle the incoming presence update
        // console.log("Got Presense update...");
        const fromJID = Strophe.getBareJidFromJid(
          presenceStanza.getAttribute("from")
        );
        // console.log(fromJID.replace(/@.*/gm, ""));
        changeOnlineStatus(fromJID.replace(/@.*/gm, ""), true);
        // console.log(newClients);
        const show = presenceStanza.querySelector("show");
        const status = presenceStanza.querySelector("status");
        // Update your own presence based on the received presence
        const updatedPresence = {
          show: show ? show.textContent : "chat", // Default to 'chat' if not available
          status: status ? status.textContent : "Available", // Default to 'Available' if not available
        };
        setMyPresence(updatedPresence);
        setReceivedPresenceUpdate(true);

        //sendPresence();
        // You can now use the presence information in your React component state or perform any other actions.
      };

      const myPresenceInterval = setInterval(() => {
        sendPresence();
      }, 3000); // 3000 milliseconds = 3 seconds

      const presenceInterval = setInterval(() => {
        //#SATHIKUM needs a relook because of the //notify API
        clients.forEach((cl) => {
          subscribeToPresence(`${cl.guid}@${config.xmppServer}`);
        });

        team.forEach((tm) => {
          tm.users.forEach((user) => {
            subscribeToPresence(`${user.guid}@${config.xmppServer}`);
          });
        });

        // console.log(newClients);

        // newClientUsers.forEach((cl) => {
        //   console.log(`Subscribing <>-<> ${cl.guid}@${config.xmppServer}`);
        //   subscribeToPresence(`${cl.guid}@${config.xmppServer}`);
        // });

        newClients.forEach((client) => {
          client.users.forEach((user) => {
            // console.log(`Subscribing <>-<> ${user.guid}@${config.xmppServer}`);
            subscribeToPresence(`${user.guid}@${config.xmppServer}`);
          });
        });
      }, 3000); // 3000 milliseconds = 3 seconds

      // Clean up the interval when the component is unmounted

      return () => {
        // Disconnect when component unmounts
        console.log("disconnecting...");
        clearInterval(presenceInterval);
        clearInterval(myPresenceInterval);
        xmppConnection.disconnect();
      };
    }
  }, [
    authToken,
    clients,
    team,
    newClientUsers,
    receivedPresenceUpdate,
    location,
  ]);

  return (
    <div className="container">
      <ToastContainer />
      {loginType === "pro" && (
        <>
          <div className="chat-container">
            <div className="region-section">
              <div className="region-title">Clients</div>

              <div className="client-cards-container">
                {newClients.map((client) => (
                  <>
                    {client.clientType.toLowerCase() === "consumer" ? (
                      <div className="client-card">
                        {/* <div className="client-name">{client.name}</div> */}
                        <div className="client-email">{client.adminName}</div>
                        <div className="action-buttons">
                          <button
                            className="action-button"
                            onClick={() =>
                              makeCall({
                                fullName: client.adminName,
                                fromJid: decodedToken.toJid,
                                toJid: client.guid,
                              })
                            }
                            style={{
                              display:
                                client.online && client.users.length === 0
                                  ? "inline-block"
                                  : "none",
                            }}
                          >
                            Call&nbsp;&nbsp;<i className="fas fa-phone"></i>
                          </button>
                          {client.online ? <></> : <>offline</>}
                          {apptInfo.map((a) => (
                            <>
                              {a.waiting &&
                              a.fromJid ===
                                client.guid + `@${config.xmppServer}` ? (
                                <button
                                  key={a.uuid}
                                  className="action-button"
                                  onClick={() => joinCall(a)}
                                >
                                  <i className="fas fa-check"></i> Accept
                                </button>
                              ) : (
                                <></>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="group-card">
                          <div className="client-name">{client.name}</div>
                          <div className="group-button">
                            <div
                              className="expand-button"
                              onClick={() => toggleClientExpand(client.id)}
                            >
                              {client.isExpanded ? "-" : "+"}
                            </div>
                          </div>
                        </div>
                        {client.isExpanded && (
                          <div className="sub-items">
                            {newClientUsers.map((member, index) => (
                              <div key={index} className="client-card">
                                <div className="client-card-primry">
                                  <div className="client-email">
                                    {member.name}
                                  </div>
                                </div>
                                <div className="action-buttons">
                                  <button
                                    className="action-button"
                                    onClick={() =>
                                      makeCall({
                                        fullName: member.name,
                                        fromJid: decodedToken.toJid,
                                        toJid: member.guid,
                                      })
                                    }
                                    style={{
                                      display: member.online
                                        ? "inline-block"
                                        : "none",
                                    }}
                                  >
                                    Call&nbsp;&nbsp;
                                    <i className="fas fa-phone"></i>
                                  </button>
                                  {member.online ? <></> : <>offline</>}

                                  {apptInfo.map((a) => (
                                    <>
                                      {a.waiting &&
                                      a.fromJid ===
                                        member.guid +
                                          `@${config.xmppServer}` ? (
                                        <button
                                          key={a.uuid}
                                          className="action-button"
                                          onClick={() => joinCall(a)}
                                        >
                                          <i className="fas fa-check"></i>{" "}
                                          Accept
                                        </button>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ))}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    )}
                  </>
                ))}

                {/* end of looop */}

                {/* <!-- Add more North America clients as needed --> */}
              </div>
            </div>

            <div className="region-section">
              <div className="region-title">Team</div>
              <div className="client-cards-container">
                {newTeam.map((team) => (
                  <>
                    <div className="group-card">
                      <div className="client-name">{team.groupName}</div>
                      <div className="group-button">
                        <div
                          className="expand-button"
                          onClick={() => toggleExpand(team.id)}
                        >
                          {team.isExpanded ? "-" : "+"}
                        </div>
                      </div>
                    </div>
                    <>
                      {team.isExpanded && (
                        <div className="sub-items">
                          {team.users.map((member, index) => (
                            <div key={index} className="client-card">
                              <div className="client-email">{member.name}</div>
                              <div className="action-buttons">
                                <button
                                  className="action-button"
                                  onClick={() =>
                                    makeCall({
                                      fullName: member.name,
                                      fromJid: decodedToken.toJid,
                                      toJid: member.guid,
                                    })
                                  }
                                  style={{
                                    display: member.online
                                      ? "inline-block"
                                      : "none",
                                  }}
                                >
                                  Call&nbsp;&nbsp;
                                  <i className="fas fa-phone"></i>
                                </button>
                                {member.online ? <></> : <>offline</>}
                                {apptInfo.map((a) => (
                                  <>
                                    {a.waiting &&
                                    a.fromJid ===
                                      member.guid + `@${config.xmppServer}` ? (
                                      <button
                                        key={a.uuid}
                                        className="action-button"
                                        onClick={() => joinCall(a)}
                                      >
                                        <i className="fas fa-check"></i> Accept
                                      </button>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  </>
                ))}

                {/* <!-- Add more Team Members as needed --> */}
              </div>
            </div>

            {/* <!-- Add more region sections as needed --> */}
          </div>
        </>
      )}

      {/* for individual relationship */}

      {loginType === "ind" && (
        <>
          <div className="chat-container">
            <div className="region-section-ind">
              <div className="region-title">Clients</div>

              <div className="client-cards-container">
                {newClients.map((client) => (
                  <>
                    {client.biztype.toLowerCase() === "consumer" ? (
                      <div className="client-card-ind">
                        {/* <div className="client-name">{client.name}</div> */}
                        <div className="client-email">
                          {client.business_name}
                        </div>
                        <div className="action-buttons">
                          <button
                            className="action-button"
                            onClick={() =>
                              makeCall({
                                fullName: client.business_name,
                                fromJid: decodedToken.toJid,
                                toJid: client.guid,
                              })
                            }
                            style={{
                              display:
                                client.online && client.users.length === 0
                                  ? "inline-block"
                                  : "none",
                            }}
                          >
                            Call&nbsp;&nbsp;<i className="fas fa-phone"></i>
                          </button>
                          {client.online ? <></> : <>offline</>}
                          {apptInfo.map((a) => (
                            <>
                              {a.waiting &&
                              a.fromJid ===
                                client.guid + `@${config.xmppServer}` ? (
                                <button
                                  key={a.uuid}
                                  className="action-button"
                                  onClick={() => joinCall(a)}
                                >
                                  <i className="fas fa-check"></i> Accept
                                </button>
                              ) : (
                                <></>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="group-card-ind">
                          <div className="client-name">
                            {client.business_name}
                          </div>
                          <div className="group-button">
                            <div
                              className="expand-button"
                              onClick={() => toggleClientExpand(client.id)}
                            >
                              {client.isExpanded ? "-" : "+"}
                            </div>
                          </div>
                        </div>
                        {client.isExpanded && (
                          <div className="sub-items">
                            {newClientUsers.map((member, index) => (
                              <div key={index} className="client-card-ind">
                                <div className="client-card-primry">
                                  <div className="client-email">
                                    {member.name}
                                  </div>
                                </div>
                                <div className="action-buttons">
                                  <button
                                    className="action-button"
                                    onClick={() =>
                                      makeCall({
                                        fullName: member.name,
                                        fromJid: decodedToken.toJid,
                                        toJid: member.guid,
                                      })
                                    }
                                    style={{
                                      display: member.online
                                        ? "inline-block"
                                        : "none",
                                    }}
                                  >
                                    Call&nbsp;&nbsp;
                                    <i className="fas fa-phone"></i>
                                  </button>
                                  {member.online ? <></> : <>offline</>}

                                  {apptInfo.map((a) => (
                                    <>
                                      {a.waiting &&
                                      a.fromJid ===
                                        member.guid +
                                          `@${config.xmppServer}` ? (
                                        <button
                                          key={a.uuid}
                                          className="action-button"
                                          onClick={() => joinCall(a)}
                                        >
                                          <i className="fas fa-check"></i>{" "}
                                          Accept
                                        </button>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ))}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    )}
                  </>
                ))}

                {/* end of looop */}

                {/* <!-- Add more  clients as needed --> */}
              </div>
            </div>

            {/* <!-- Add more region sections as needed --> */}
          </div>
        </>
      )}
    </div>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    setIdentityAction: (identity) => dispatch(setIdentity(identity)),
    setIsRoomHostAction: (isRoomHost) => dispatch(setIsRoomHost(isRoomHost)),
    setFromJidAction: (fromJid) => dispatch(setFromJid(fromJid)),
    setToJidAction: (toJid) => dispatch(setToJid(toJid)),
    setInRequestAction: (inRequest) => dispatch(setInRequest(inRequest)),
    setCallFromAction: (callFrom) => dispatch(setCallFrom(callFrom)),
    setCofferTokenAction: (cofferToken) =>
      dispatch(setCofferToken(cofferToken)),
    setTeamAction: (team) => dispatch(setTeam(team)),
    setConnectionAction: (connection) => dispatch(setConnection(connection)),
    setClientUsersAction: (clientUsers) =>
      dispatch(setClientUsers(clientUsers)),
    setLoginTypeAction: (loginType) => dispatch(setLoginType(loginType)),
    setCofferEmailAction: (cofferEmail) =>
      dispatch(setCofferEmail(cofferEmail)),
  };
};

export default connect(mapStoreStateToProps, mapActionsToProps)(Users);
