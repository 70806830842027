import React from "react";
import { connect } from "react-redux";
import { getClass } from "../../utils/common";

// const dummyParticipants = [
//   { identity: "Musk" },
//   { identity: "Gates" },
//   { identity: "Ellison" },
//   { identity: "Buffett" },
// ];

const SingleParticipant = (props) => {
  const { identity, lastItem, participant } = props;

  return (
    <>
      <div className="participants_container_item">
        <div className={getClass(identity)}>
          {identity.substring(0, 1).toUpperCase()}
        </div>
        <p className="participants_paragraph">{identity}</p>
        {!lastItem && <span className="particiipants_seperator_line"></span>}
      </div>
    </>
  );
};

const Participants = ({ participants }) => {
  // console.log(participants);
  return (
    <div className="participants_container">
      {participants.map((participant, index) => {
        return (
          <SingleParticipant
            key={participant.identity}
            identity={participant.identity}
            lastItem={participants.length === index + 1}
            participant={participant}
          />
        );
      })}
    </div>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStoreStateToProps)(Participants);
