import React, { useState } from "react";
import record from "./../../resources/images/record.svg";
import stop from "./../../resources/images/stop.svg";

import { Tooltip } from "react-tooltip";
import { connect } from "react-redux";
import { setIsRecordingOn, setShowError } from "../../store/actions";
import * as webRTCHandler from "../../utils/webRTCHandler";
import toast, { Toaster } from "react-hot-toast";

const RecordButton = (props) => {
  const {
    setIsRecordingOnAction,
    setShowErrorAction,
    isRecordingOn,
    participants,
  } = props;
  const [isRecordOn, setIsRecordOn] = useState(false);
  const notify = () =>
    toast.error("There is no other participants to start Recording", {
      position: "top-center",
    });

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleRecordButtonPressed = async () => {
    console.log("Record Button clicked...");
    console.log(participants.length);
    if (participants.length < 2 && !isRecordOn) {
      setShowErrorAction(true);
      await delay(2000);
      setShowErrorAction(false);
    } else {
      setShowErrorAction(false);
      setIsRecordOn(!isRecordOn);
      setIsRecordingOnAction(!isRecordingOn);
      console.log(isRecordingOn);
      if (!isRecordOn) {
        webRTCHandler.startSaveLocalStream();
        webRTCHandler.startSaveRemoteStream();
      } else {
        await webRTCHandler.stopSaveLocalStream();
        await webRTCHandler.stopSaveRemoteStream();
      }
    }
  };
  return (
    <div className="video_button_container">
      <Tooltip id="my-tooltip" />
      {/* <object
        ref={objectRef}
        type="image/svg+xml"
        data={isMicMuted ? micOff : mic}
      ></object> */}
      <img
        className="video_button_image small-img"
        src={isRecordOn ? stop : record}
        xmlns="http://www.w3.org/2000/svg"
        onClick={handleRecordButtonPressed}
        data-tooltip-id="my-tooltip"
        data-tooltip-content={isRecordOn ? "Record On" : "Record Off"}
      />
    </div>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    setIsRecordingOnAction: (isRecordingOn) =>
      dispatch(setIsRecordingOn(isRecordingOn)),
    setShowErrorAction: (showError) => dispatch(setShowError(showError)),
  };
};

export default connect(mapStoreStateToProps, mapActionsToProps)(RecordButton);
